import React from 'react';

const ServicesAll = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.5421 41.6087H12.1385C12.0281 41.6041 11.9214 41.5705 11.8282 41.5112C11.735 41.4519 11.6589 41.3693 11.608 41.2713C11.5551 41.1797 11.5273 41.0757 11.5273 40.9699C11.5273 40.864 11.5551 40.7601 11.608 40.6684L22.8216 24.1248C22.8771 24.0452 22.9516 23.9804 23.0377 23.9355C23.1237 23.8906 23.2191 23.8672 23.3161 23.8672C23.4132 23.8672 23.5091 23.8906 23.5952 23.9355C23.6813 23.9804 23.7551 24.0452 23.8107 24.1248L35.0243 40.6684C35.0824 40.7582 35.1132 40.8629 35.1132 40.9699C35.1132 41.0768 35.0824 41.1815 35.0243 41.2713C34.9794 41.3641 34.9115 41.4439 34.827 41.503C34.7426 41.5621 34.6446 41.5983 34.5421 41.6087ZM13.2718 40.4029H33.4087L23.3526 25.5237L13.2718 40.4029Z"
      fill="#475467"
    />
    <path
      d="M12.2112 41.6045C12.1007 41.5876 11.9973 41.5403 11.9121 41.4679C11.8269 41.3955 11.7635 41.3011 11.729 41.1947L4.80749 19.8765C4.77195 19.7726 4.76715 19.6606 4.79277 19.5539C4.81839 19.4471 4.87351 19.35 4.95233 19.2736C5.02684 19.1991 5.11905 19.1444 5.22021 19.1149C5.32137 19.0854 5.42819 19.0819 5.53108 19.1046L23.6662 23.9278C23.7574 23.9532 23.8418 23.9991 23.9128 24.0617C23.9839 24.1244 24.0393 24.2022 24.0759 24.2896C24.1224 24.3746 24.1472 24.47 24.1472 24.5669C24.1472 24.6638 24.1224 24.7591 24.0759 24.8442L12.8865 41.3875C12.809 41.4877 12.7032 41.5619 12.5827 41.6007C12.4622 41.6394 12.3325 41.6407 12.2112 41.6045ZM6.20639 20.5274L12.4043 39.6271L22.4362 24.8201L6.20639 20.5274Z"
      fill="#475467"
    />
    <path
      d="M23.4255 25.165C23.2992 25.1999 23.1661 25.1999 23.0398 25.165C22.9694 25.1063 22.9132 25.0325 22.8755 24.9489C22.8379 24.8653 22.8197 24.7744 22.8226 24.6828V6.71637C22.8223 6.60791 22.8516 6.50152 22.9068 6.40815C22.9619 6.31478 23.0412 6.23795 23.1364 6.18589C23.2298 6.12566 23.3384 6.09375 23.4496 6.09375C23.5608 6.09375 23.6699 6.12566 23.7634 6.18589L41.8985 19.353C41.992 19.42 42.0642 19.5126 42.1069 19.6194C42.1496 19.7262 42.1608 19.8428 42.1393 19.9559C42.1178 20.0682 42.0662 20.1726 41.9897 20.2576C41.9132 20.3426 41.8148 20.4052 41.7054 20.4384L23.5703 25.2615L23.4255 25.165ZM24.0283 7.77761V23.7663L40.1622 19.522L24.0283 7.77761Z"
      fill="#475467"
    />
    <path
      d="M34.5425 41.6136C34.446 41.6152 34.351 41.5916 34.2664 41.545C34.1818 41.4985 34.1107 41.4308 34.0603 41.3484L22.8461 24.805C22.8111 24.6952 22.8111 24.5772 22.8461 24.4674C22.8344 24.3955 22.8344 24.3223 22.8461 24.2504C22.8827 24.1631 22.9388 24.0852 23.0098 24.0226C23.0808 23.9599 23.1653 23.9141 23.2565 23.8886L41.3916 19.0655C41.4982 19.0429 41.6086 19.0464 41.7136 19.0758C41.8186 19.1052 41.9151 19.1597 41.9945 19.2345C42.0987 19.3556 42.1578 19.5086 42.1629 19.6684C42.1733 19.7242 42.1733 19.7816 42.1629 19.8374L35.242 41.1556C35.2075 41.2619 35.144 41.3567 35.0588 41.4291C34.9737 41.5014 34.8703 41.5487 34.7598 41.5656L34.5425 41.6136ZM24.3175 24.7326L34.5425 39.6363L40.7645 20.5365L24.3175 24.7326Z"
      fill="#475467"
    />
    <path
      d="M23.4246 25.156H23.2562L5.1211 20.3328C5.01164 20.2997 4.91324 20.2371 4.83673 20.1521C4.76021 20.0671 4.70866 19.963 4.68718 19.8506C4.66566 19.7376 4.67683 19.6207 4.71956 19.5139C4.76229 19.407 4.83445 19.3148 4.92798 19.2478L23.0631 6.08037C23.1591 6.02759 23.2673 6 23.3769 6C23.4865 6 23.594 6.02759 23.6901 6.08037C23.7881 6.13124 23.871 6.20711 23.9303 6.30027C23.9896 6.39343 24.0229 6.5008 24.0275 6.61114V24.5772C24.0257 24.6705 24.0032 24.7623 23.9615 24.8457C23.9198 24.9291 23.8602 25.002 23.7866 25.0594C23.6766 25.1228 23.5515 25.1561 23.4246 25.156ZM6.66425 19.513L22.8217 23.7574V7.76864L6.66425 19.513Z"
      fill="#475467"
    />
  </svg>
);

export default ServicesAll;
